import React from 'react';
import * as styles from './rcFormErrors.module.scss';

import PropTypes from "prop-types";

const RcFormErrors = ( { className = "", errors, touched } ) => {


  return <div className={className}>
    {
      Object.keys(errors).map( e => {

        if (!touched[e]) {
          return null;
        }

        return <div className={styles.error}>
          { errors[e] }
        </div>

      } )
    }
  </div>

};

RcFormErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired
}

export default RcFormErrors;

import React from 'react';
import * as styles from './footer.module.scss';

import FooterLink from "./footer-link";

import BrandWorkmarkSVG from "../../icons/reischauer/brandwordMark.svg";

import YoutubeSVG from "../../icons/fontawesome/brands/youtube.svg";
import LinkedInSVG from "../../icons/fontawesome/brands/linkedin.svg";
import FacebookSVG from "../../icons/fontawesome/brands/facebook.svg";
import XINGSVG from "../../icons/fontawesome/brands/xing.svg";
import RcButton from "../../components/button/rcButton"
import { useAuthContext } from "../../provider/authProvider"

const Footer = ({ className = ""}) => {

  const authContext = useAuthContext();

  return <div className={styles.footer +  " py-6 " + className}>

    <div className="container">

      <div className="row mb-5 mb-xl-6">
        <div className={"col-12 " + styles.imprint}>

          <div className="d-flex flex-column flex-lg-row">

            <div className={"mr-auto"}>
              <BrandWorkmarkSVG className={styles.brandwordMark + " "} />
            </div>





            <div className={"ml-0 ml-lg-auto mt-4 mt-lg-0 text-white d-flex align-items-center " + styles.socialMedias}>
              <a href={"https://www.youtube.com/user/ThomasReischauer"} target={"_blank"}>
                <YoutubeSVG className={"svgFill " + styles.youtube} />
              </a>

              <a href={"https://www.linkedin.com/in/thomas-reischauer-3999b114/"} target={"_blank"}>
                <LinkedInSVG className={"svgFill "} />
              </a>

              {/*<a href={"https://twitter.com/ReischauerC"} target={"_blank"}>*/}
              {/*  <TwitterSVG className={"svgFill " + styles.twitter} />*/}
              {/*</a>*/}

              {/*<LinkedInSVG className={"svgFill"} />*/}

              <a href={"https://www.facebook.com/reischauerconsulting"} target={"_blank"}>
                <FacebookSVG className={"svgFill " + styles.facebook} />
              </a>

              <a href={"https://www.xing.com/companies/reischauerconsultinggmbh"} target={"_blank"}>
                <XINGSVG className={"svgFill " + styles.xing} />
              </a>


              <div className="ml-3 d-none d-lg-block">
                <RcButton onClick={ () => authContext.showNewsletterModal() }>
                  Newsletter abonnieren
                </RcButton>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div className="row">

        <div className="col-lg-4 col-md-6">
          <h4>Kontakt</h4>


          <a href="mailto:office@reischauer.at">office@reischauer.at</a>
          <p>
            <a href={"tel:+437242900111"}>+43 7242 9001-11</a>
          </p>

          <br />

          <p>Durisolstraße 7</p>
          <p>A-4600 Wels</p>
        </div>

        <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
          <h4>Navigation</h4>

          <ul>
            <FooterLink to={"/"}>Startseite</FooterLink>
            <FooterLink to={"/beratung"}>Beratung</FooterLink>
            <FooterLink to={"/events"}>Events</FooterLink>
            <FooterLink to={"/wissen"}>Wissen</FooterLink>
            <FooterLink to={"/immobilien"}>Immobilien</FooterLink>
            <FooterLink to={"/gutachten"}>Gutachten</FooterLink>
            <FooterLink to={"/betriebsmarkt"}>Betriebsmarkt</FooterLink>
            <FooterLink to={"/about?state=team"}>Über uns</FooterLink>
            <FooterLink to={"/about?state=partner"}>Partner</FooterLink>
          </ul>
        </div>

        <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
          <h4>Disclaimer</h4>

          <ul>
            <FooterLink to={"/impressum"}>
              Impressum
            </FooterLink>

            <FooterLink to={"/datenschutzerklaerung"}>
              Datenschutzerklärung
            </FooterLink>

            <FooterLink to={"/allgemeine-geschaftsbedingungen"}>
              Allgemeine Geschäftsbedingungen
            </FooterLink>

            <FooterLink to={"/genderhinweis"}>
              Genderhinweis
            </FooterLink>

          </ul>

        </div>



      </div>
    </div>

  </div>
};

export default Footer;

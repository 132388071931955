import React, { useRef } from "react"
import RcModal from "./rcModal"
import { useAuthContext } from "../../provider/authProvider"
import RcButton from "../../components/button/rcButton";
import * as Yup from "yup";

import { Formik } from "formik";
import RcFormErrors from "../helper/rcFormErrors"
import axios from "axios"
import { getStrapiUrl } from "../../helper/uri"
import { toast } from 'react-toastify';

const RcLoginModal = () => {

  const authContext = useAuthContext();
  const mailInputRef = useRef();

  const loginScheme = Yup.object().shape(
    {
      mail: Yup.string().email("Ungültige Mail-Adresse").required("E-Mail Adresse notwendig"),
      password: Yup.string().required("Passwort notwendig")
    }
  );

  const forgotPassword = async (email) => {
    try {
      await axios.post(`${getStrapiUrl()}/auth/forgot-password`, { email });
      toast("Sie haben Post bekommen, bitte prüfen Sie auch Ihren Spam-Ordner");
    } catch(err) {
      toast("Ihr Passwort konnte nicht zurückgesetzt werden. Bitte prüfen Sie Ihre Eingaben");
    }
  }

  return <RcModal title={"Login"} onRequestClose={authContext.hideModal}>

    <Formik
      initialValues={
        {
          mail: '',
          password: ''
        }
      }

      validationSchema={loginScheme}

      onSubmit={
        async (values) => {
          try {
            await authContext.login(values.mail, values.password);
          } catch(err) {

          }
        }
      }
    >
      {
        ( {
            values,
            handleChange,
            handleBlur,
            handleSubmit,

            touched,
            errors
        } ) => {
          return <form className={"rc-form"}>

            <RcFormErrors touched={touched} errors={errors} />

            <div className="rc-form-control">

              <label htmlFor="mail">E-Mail</label>

              <input name="mail"
                     ref={mailInputRef}
                     id="mail"
                     type="email"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.mail} />

            </div>

            <div className="rc-form-control">

              <label htmlFor="password">Passwort</label>

              <input type="password"
                     id="password"
                     name="password"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.password}
              />

            </div>

            <RcButton onClick={handleSubmit} className={"d-inline-block mt-2 mb-4"}>
              Login
            </RcButton>

            {authContext.showPasswordReset &&
            <div className={"mt-0 text-primary c-pointer"}
              onClick={
                async () => {
                  const mailInput = mailInputRef.current.value;
                  await forgotPassword(mailInput);
                }
              }
            >
              <p>Passwort vergessen?</p>
            </div>
            }


          </form>;
        }

      }
    </Formik>


  </RcModal>;

}

export default RcLoginModal;

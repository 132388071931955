import React from 'react';
import { useAuthContext } from "../provider/authProvider"
import RcLoginModal from "./modal/rcLoginModal"
import RcRegisterModal from "./modal/rcRegisterModal"
import { RcNewsletterpopup } from "./modal/rcNewsletterpopup"

const LayoutModal = () => {

  const authContext = useAuthContext();

  return <>
    { authContext.currentModal === 'login' && <RcLoginModal /> }
    { authContext.currentModal === 'register' && <RcRegisterModal /> }
    { authContext.currentModal === "newsletter" && <RcNewsletterpopup /> }
  </>

};

export default LayoutModal;

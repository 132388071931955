import React, { useState } from "react"
import { Link } from 'gatsby';
import * as styles from './header.module.scss';

import BarsSVG from "../../icons/fontawesome/light/bars.svg";
import SignOutSVG from "../../icons/fontawesome/light/sign-out.svg";
import CogsSVG from "../../icons/fontawesome/solid/cogs.svg";

import LogoSVG from "../../icons/reischauer/logo.svg";
import RcHeaderMenu from "./rcHeaderMenu"
import { useAuthContext } from "../../provider/authProvider"
import { toast } from "react-toastify"

const Header = ( {
                   className,
                   sticky,
                   toggleMobileOverlay = () => { }
                 } ) => {

  const authContext = useAuthContext();

  return <div className={"shadow-xl " + styles.header + " " + className + " " + (sticky ? styles.sticky : "")}>

    <div className="container p-relative d-flex align-items-center align-items-lg-end">

      <div className={styles.account + " d-none d-lg-block"}>

        { !authContext.currentUser && <>
          <a className={styles.accountSep} onClick={authContext.showLoginModal}>
            Login
          </a>

          <a onClick={authContext.showRegisterModal}>
            Registrieren
          </a>

        </> }

        { authContext.currentUser &&
        <div className={"d-flex align-items-center"}>
          <p className={"mr-2"} >{authContext.currentUser.username}</p>

          <Link to={"/profil"}>
            <CogsSVG className={"text-white svgFill mr-3"} />
          </Link>

          <SignOutSVG className={"svgFill c-pointer"}
            onClick={
              async () => {
                await authContext.logout();
              }
            }
          />
        </div>
        }
      </div>

      <Link to={"/"}>
        <LogoSVG className={styles.headerLogo + " mr-auto"} />
      </Link>

      <div className="ml-auto d-none d-lg-block pb-2">
        <RcHeaderMenu className={styles.menuMenu} />
      </div>

      <div className="ml-auto d-block d-lg-none">
        <BarsSVG className={styles.barsSvg} onClick={toggleMobileOverlay} />
      </div>

    </div>


  </div>
};

export default Header;

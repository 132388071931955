import React from 'react';
import * as styles from './rcButton.module.scss';

const RcButton = ( {
                     children,
                     className = "",
                     onClick = () => { },
                     style = "normal",
                     small = false
                   } ) => {
  return <button className={styles.btn + " " + className + " " + styles[style] + " " + (small ? styles.small : "")}
                 onClick={onClick}>
    { children }
  </button>
};

export default RcButton;

import React, { useEffect, useRef } from "react"
import * as styles from './rcMobileOverlay.module.scss';

import TimesSVG from "../../icons/fontawesome/light/times.svg";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import RcHeaderMenu from "../header/rcHeaderMenu"

const RcMobileOverlay = (
  {
    toggleOverlay = () => { },
    isVisible = false
  }
) => {

  const ref = useRef();

  useEffect(
    () => {
      if (!isVisible) {
        clearAllBodyScrollLocks();
      }

      if (ref.current) {
        disableBodyScroll(ref.current);
      }

    },
    [ isVisible ]
  );

  if (!isVisible) {
    return null;
  }

  return <div className={styles.overlay + " p-5"} ref={ref}>
    <div className={"d-flex align-items-center justify-content-between " + styles.top}>
      Navigation
      <TimesSVG className={"svgFill"} onClick={toggleOverlay} />
    </div>

    <RcHeaderMenu hideHeaderMenu={ () => { toggleOverlay() } }
      style={"light"} className={styles.menu} />

  </div>

};

export default RcMobileOverlay;

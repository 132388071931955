import React from 'react';
import { Link } from "gatsby"

import WissenSVG from "../../icons/envato/wissen.svg"
import KalendarSVG from "../../icons/envato/kalender.svg"
import BeratungSVG from "../../icons/reischauer/beratung.svg"
import ImmobilienSVG from "../../icons/reischauer/immobilien.svg"
import GutachtenSVG from "../../icons/reischauer/gutachten.svg"
import UebergabeSVG from "../../icons/reischauer/uebergabe.svg"
import TeamSVG from "../../icons/envato/team.svg"

import BeratungLightSVG from "../../icons/reischauer/beratungLight.svg";
import GutachtenLightSVG from "../../icons/reischauer/gutachtenLight.svg";
import ImmobilienLightSVG from "../../icons/reischauer/immobilienLight.svg";
import UebergabeLightSVG from "../../icons/reischauer/uebergabeLight.svg";

import UserPlusSVG from "../../icons/fontawesome/light/user-plus.svg";
import SignInSVG from "../../icons/fontawesome/light/sign-in.svg";

import { useAuthContext } from "../../provider/authProvider"

const RcHeaderMenu = ( {
                         className = '',
                         style = 'dark',
                         hideHeaderMenu = () => { }
                       }) => {

  const authContext = useAuthContext();

  return <ul className={className}>

    <li>
      <Link to={"/beratung"}>
        { style === 'dark' && <BeratungSVG className={"svgFill"} /> }
        { style === 'light' && <BeratungLightSVG className={"svgFill"} /> }
        Beratung
      </Link>
    </li>

    <li>
      <Link to={"/events"}>
        <KalendarSVG className={"svgFill"} />
        Events
      </Link>
    </li>

    <li>
      <Link to={"/wissen"}>
        <WissenSVG className={"svgFill"} />
        Wissen
      </Link>
    </li>

    <li>
      <Link to={"/immobilien"}>
        { style === 'dark' && <ImmobilienSVG className={"svgFill"}/> }
        { style === 'light' && <ImmobilienLightSVG className={"svgFill"} /> }
        Immobilien
      </Link>
    </li>

    <li>
      <Link to={"/gutachten"}>
        { style === 'dark' && <GutachtenSVG className={"svgFill"}/> }
        { style === 'light' && <GutachtenLightSVG className={"svgFill"} /> }
        Gutachten
      </Link>
    </li>

    <li>
      <Link to={"/betriebsmarkt"}>
        { style === 'dark' && <UebergabeSVG className={"svgFill"} /> }
        { style === 'light' && <UebergabeLightSVG className={"svgFill"} /> }
        Betriebsmarkt
      </Link>
    </li>

    <li>
      <Link to={"/about"}>
        <TeamSVG className={"svgFill"} />
        Über uns
      </Link>
    </li>


    { !authContext.currentUser &&
    <>
      <li className={"d-block d-lg-none mt-5"}>
        <a
          onClick={ () => {
            hideHeaderMenu();
            authContext.showLoginModal();
          }}
        >
          <SignInSVG className={"svgFill"} />
          Login
        </a>
      </li>

      <li className={"d-block d-lg-none"}>
        <a
          onClick={
            () => {
              hideHeaderMenu();
              authContext.showRegisterModal();
            }
          }
        >
          <UserPlusSVG className={"svgFill"} />
          Registrieren
        </a>
      </li>
    </>

    }


  </ul>
};

export default RcHeaderMenu;


/*
{ !authContext.currentUser && <>
          <a className={styles.accountSep} onClick={authContext.showLoginModal}>
            Login
          </a>

          <a onClick={authContext.showRegisterModal}>
            Registrieren
          </a>

        </> }

        { authContext.currentUser &&
        <div className={"d-flex align-items-center"}>
          <p className={"mr-2"} >{authContext.currentUser.username}</p>

          <Link to={"/profil"}>
            <CogsSVG className={"text-white svgFill mr-3"} />
          </Link>

          <SignOutSVG className={"svgFill c-pointer"}
            onClick={
              async () => {
                await authContext.logout();
              }
            }
          />
        </div>
        }
      </div>
 */

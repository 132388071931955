import React, { useContext } from "react"
import { AuthContext } from "../../provider/authProvider"
import RcModal from "./rcModal"

export const RcNewsletterpopup = () => {

  const authContext = useContext(AuthContext);

  return <RcModal title={"Newsletter"} onRequestClose={authContext.hideModal}>

    <iframe data-w-type="embedded" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
            src="https://xyo08.mjt.lu/wgt/xyo08/4ll/form?c=901996df" height={"500"} width="100%"></iframe>

  </RcModal>
}

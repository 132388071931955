import React, { createContext, useContext, useEffect, useState } from "react"
import { getStrapiUrl } from "../helper/uri"
import axios from "axios";
import Cookie from "js-cookie";
import { toast } from 'react-toastify';

axios.interceptors.request.use(
  (config) => {

    if (config.url.indexOf('api.betrieb-zu-haben.at') !== -1) {
      return config;
    }

    const token = Cookie.get("token");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const AuthContext = createContext();

export const AuthProvider = ( { children } ) => {

  const [ currentUser, setCurrentUser ] = useState(null);
  const [ currentModal, setCurrentModal ] = useState(null);

  const [ showPasswordReset, setShowPasswordReset ] = useState(false);

  const loadUserDetail = async () => {
    const token = Cookie.get("token");

    if (token) {
      try {
        const res = await axios.get(`${getStrapiUrl()}/users/me`);
        setCurrentUser(res.data);

        // hide any visible modal
        setCurrentModal(null);
      } catch(err) {
        Cookie.remove("token");
      }
    }
  };

  useEffect(
    () => {
      loadUserDetail();
    },
    []
  );

  const providerValue = {
    currentUser,
    setCurrentUser,

    currentModal,
    setCurrentModal,

    hideModal: () => setCurrentModal(undefined),
    showLoginModal: () => {
      setCurrentModal('login');
    },
    showRegisterModal: () => setCurrentModal('register'),

    showNewsletterModal: () => setCurrentModal('newsletter'),

    register: async (email, password, firstname, lastname) => {
      if (typeof window === "undefined") {
        return;
      }

      try {
        const res = await axios.post(`${getStrapiUrl()}/auth/local/register`, {
          email,
          password,
          username: firstname + " " + lastname
        });
        Cookie.set("token", res.data.jwt, { expires: 365 });

        await loadUserDetail();
      } catch(err) {
        toast("Registrierung fehlgeschlagen? Evtl. existiert Konto bereits?");
      }
    },

    login: async (identifier, password) => {
      if (typeof window === "undefined") {
        return;
      }

      try {
        const res = await axios.post(`${getStrapiUrl()}/auth/local/`, { identifier, password });
        Cookie.set("token", res.data.jwt, { expires: 365 });
      } catch(err) {
        setShowPasswordReset(true);
      }

      try {
        await loadUserDetail();
      } catch(err) {
        console.log('*** load user detail ', err);
        toast("Es ist ein interner Fehler passiert! Bitte probieren Sie es später erneut");
        setCurrentModal(null);
      }
    },

    logout: async () => {
      Cookie.remove("token");
      delete window.__user;

      // sync logout between multiple windows
      window.localStorage.setItem("logout", Date.now());

      setCurrentUser(null);

      // navigate to index page always
      window.location = "/";
    },

    showPasswordReset,

    activateAbo: async () => {
      await axios.post(getStrapiUrl() + "/activate-abo");
      await loadUserDetail();
    },

    activateEvent: async(id) => {
      await axios.post(getStrapiUrl() + "/activate-event/" + id);
      await loadUserDetail();
    },

    loadUserDetail

  };

  return (
    <AuthContext.Provider value={providerValue}>
      { children }
    </AuthContext.Provider>
  );

};

export const useAuthContext = () => {
  return useContext(AuthContext);
}

import React from 'react';
import { useAuthContext } from "../../provider/authProvider"
import RcModal from "./rcModal"
import RcButton from "../../components/button/rcButton"

import { Formik } from "formik";
import * as Yup from "yup";
import RcFormErrors from "../helper/rcFormErrors"

const RcRegisterModal = () => {
  const authContext = useAuthContext();

  const registerScheme = Yup.object().shape(
    {
      mail: Yup.string().email("Ungültige Mail-Adresse").required("E-Mail Adresse notwendig"),
      password: Yup.string().required("Password notwendig"),
      firstname: Yup.string().required("Vorname notwendig"),
      lastname: Yup.string().required("Nachname notwendig")
    }
  )

  return <RcModal title={"Registrieren"} onRequestClose={authContext.hideModal}>

    <Formik initialValues={
              {
                mail: '',
                password: '',
                firstname: '',
                lastname: ''
              }
            }

            validationSchema={registerScheme}

            onSubmit={
              async (values) => {
                await authContext.register(values.mail, values.password, values.firstname, values.lastname);
              }
            }>
      {
        ( {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors
        } ) => {
          return <form className={"rc-form"}>

            <RcFormErrors touched={touched} errors={errors} />

            <div className="rc-form-control">
              <label htmlFor="mail">E-Mail</label>
              <input id={"mail"}
                     required={true}
                     name={"mail"}
                     type="email"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.mail}
              />
            </div>

            <div className="rc-form-control">
              <label htmlFor="password">Passwort</label>
              <input id={"password"}
                     required={true}
                     name={"password"}
                     type="password"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.password}
              />
            </div>

            <div className="rc-form-control">
              <label htmlFor="firstname">Vorname</label>
              <input id={"firstname"}
                     required={true}
                     name={"firstname"}
                     type="text"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.firstname}
              />
            </div>

            <div className="rc-form-control">
              <label htmlFor="lastname">Nachname</label>
              <input id={"lastname"}
                     required={true}
                     name={"lastname"}
                     type="text"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.lastname}
              />
            </div>



            <RcButton className={"d-inline-block mt-2 mb-4"}
                      onClick={handleSubmit}>
              Registrieren
            </RcButton>

          </form>
        }
      }
    </Formik>



  </RcModal>

};

export default RcRegisterModal;

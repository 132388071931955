import React, { useEffect, useRef, useState } from "react"
import * as styles from './layout.module.scss';

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../style/all.scss";
import "../style/custom-swiper/custom-swiper.scss";
import "../style/custom-swiper/custom-swiper-navigation.scss";
import "../style/custom-swiper/custom-swiper-pagination.scss";

import Header from "./header/header"
import Footer from "./footer/footer"
import RcMobileOverlay from "./mobileOverlay/rcMobileOverlay"

import { clearAllBodyScrollLocks } from "body-scroll-lock"
import { AuthProvider, useAuthContext } from "../provider/authProvider"
import LayoutModal from "./layoutModal"

import cookiejs from "cookiejs";

import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/merriweather/300.css";
import "@fontsource/merriweather/400.css";
import "@fontsource/merriweather/700.css";
import "@fontsource/montserrat/100.css"
import "@fontsource/montserrat/200.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"

import posthog from 'posthog-js'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [ sticky, setSticky ] = useState(false);
  const [ showMobileOverlay, setShowMobileOverlay ] = useState(false);

  const mobileOverlayRef = useRef(null);

  useEffect(
    () => {
      if (!window) {
        return;
      }

      const scrollHandler = (event) => {
        const scrollY = window.scrollY;

        if (scrollY > 500) {
          setSticky(true);
        }

        if (scrollY < 300) {
          setSticky(false);
        }
      };


      window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      }
    },
    []
  );

  const authContext = useAuthContext();

  // cookie handler check
  useEffect(
    () => {

      setTimeout(
        () => {

          if (!authContext) return;

          const newsletter = cookiejs.get("newsletter");
          if (newsletter) {
            if ( ( Date.now() < Number.parseInt(newsletter) )) {
              return;
            }
          }

          cookiejs.set("newsletter", Date.now() + (1000 * 60 * 60 * 24 * 30));
          authContext.showNewsletterModal();
        },
        20000
      );


    },
    [ authContext ]
  );

  useEffect(
    () => {
      posthog.init('phc_uKzfjJaeeaOT857q2z4EDpD3yJQ1v0JlwJaCZhLx1OL', { api_host: 'https://eu.posthog.com' })
    },
    []
  );

  return (
    <AuthProvider>

      <ToastContainer />

      <LayoutModal />

      <div className={styles.layout}>

        <script dangerouslySetInnerHTML={
          {
            __html:  `(function() { var s = document.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = '//api.usersnap.com/load/103db6fc-a296-4bbc-aa01-09c6948d89eb.js';
  var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`
          }
        } />

        <Header
                sticky={sticky}
                className={styles.header}
                toggleMobileOverlay={
                  () => {
                    setShowMobileOverlay(s => !s);
                  }
              }
        />

        <RcMobileOverlay
              ref={mobileOverlayRef}
              isVisible={showMobileOverlay}
              toggleOverlay={
                () => {
                  clearAllBodyScrollLocks();
                  setShowMobileOverlay(s => !s);
                }
              } />

        <main className={styles.content}>
            {children}
        </main>

        <Footer className={styles.footer} />

      </div>
    </AuthProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

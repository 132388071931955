import React from 'react';
import { Link } from "gatsby";
import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg"

const FooterLink = ( { children, to } ) => {
  return <li>
    <Link className="d-flex align-items-center" to={to}>
      <ChevronRightSVG className={"svgFill"} /> {children}
    </Link>
  </li>;
};

export default FooterLink;

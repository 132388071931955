import React, { useEffect, useRef } from "react"
import * as styles from './rcModal.module.scss';
import Modal from "react-modal";

import TimesSVG from "../../icons/fontawesome/light/times.svg";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { useWindowWidth } from "@react-hook/window-size"

Modal.setAppElement(`#___gatsby`);

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    borderRadius: '12px'
  },
  overlay: {
    backgroundColor : 'rgba(0,0,0,.7)',
    zIndex: 988000
  }
};

const mobileCustomStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    overflowY: 'auto',
    padding: '15px',
    backgroundColor: '#fffdf8',
  },

  overlay: {
    zIndex: 59000
  }
};


const RcModal = ( {
                    children,
                    title,
                    onRequestClose = () => { }
                  } ) => {

  const width = useWindowWidth();
  const modalRef = useRef();

  useEffect(
    () => {
      if (modalRef.current) {
        disableBodyScroll(modalRef.current);
      }
    },
    []
  );

  return <Modal isOpen={true}
                ref={modalRef}
                onAfterClose={
                  () => { clearAllBodyScrollLocks() }
                }
                onRequestClose={onRequestClose}
                style={ width <= 767 ? mobileCustomStyles : customStyles}>

    <div className={styles.modalContent}>

      <div className={"d-flex align-items-center mb-2 justify-content-between mb-2 mt-3 " + styles.modalHeader}>
        <h4>{title}</h4>
        <TimesSVG className={styles.close + " c-pointer"} onClick={onRequestClose} />
      </div>

      { children }

    </div>

  </Modal>;

};

export default RcModal;
